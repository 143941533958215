<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">予診票{{ interpreter1(newOrEdit) }}</h2>
    <div class="mx-6">
      <v-radio-group v-model="how2make" row>
        <v-radio
          :label="'Formから' + interpreter1(newOrEdit)"
          value="form"
        ></v-radio>
        <v-radio
          :label="'Jsonから' + interpreter1(newOrEdit)"
          value="json"
        ></v-radio>
      </v-radio-group>
      <v-form ref="questionForm">
        <v-card>
          <v-text-field
            class="ma-4"
            label="予診票名"
            single-line
            :rules="[(v) => !!v || '健診の名前を記入して下さい']"
            v-model="title"
          ></v-text-field>
        </v-card>

        <!-- <v-select
          :items="defaultQuestions"
          label="デフォルト質問"
          solo
          multiple
          v-model="selectedQuestions"
        ></v-select> -->

        <!-- <v-card v-for="(selected_question, i) in selectedQuestions" :key="i">
          <v-system-bar color="grey lighten-2" dark>
            <v-spacer></v-spacer>
          </v-system-bar>
          <div class="mx-4 mb-4">
            <v-text-field
              :label="translateQuestions(selected_question).text"
              single-line
              disabled
            ></v-text-field>
          </div>
        </v-card> -->

        <v-card v-for="(question, lI) in defaultQuestions" :key="lI">
          <v-system-bar color="grey lighten-2" dark> </v-system-bar>
          <div class="mx-4 mb-4">
            <v-text-field
              :label="question.text"
              single-line
              disabled
            ></v-text-field>
          </div>
        </v-card>

        <div v-for="(question, i) in questions" :key="i + 100">
          <v-card>
            <v-system-bar color="grey" dark>
              <div>{{ i + 1 }}</div>
              <v-spacer></v-spacer>
              <v-icon @click="deletePushed(i)">mdi-trash-can-outline</v-icon>
            </v-system-bar>
            <div class="ma-4">
              <v-text-field
                label="質問内容"
                single-line
                v-model="question.text"
                :rules="[(v) => !!v || '質問内容は必須です']"
              ></v-text-field>
              <v-radio-group label="回答" row readonly v-model="radios">
                <v-radio label="はい" value="yes"></v-radio>
                <v-radio label="いいえ"></v-radio>
              </v-radio-group>
              <v-text-field
                label="メモ"
                single-line
                filled
                hint="メモ欄に記入して欲しい内容を入力して下さい"
                persistent-hint
                counter="10"
                v-model="question.memo"
                :rules="[(v) => v.length <= 10 || '10文字以内で入力して下さい']"
              ></v-text-field>
            </div>
          </v-card>
        </div>
      </v-form>
      <v-row justify="end" class="mr-2 pt-2">
        <v-btn color="primary" class="mr-2" @click="addPushed"
          >質問を追加</v-btn
        >
        <v-btn color="primary" @click="makePushed"
          >予診票{{ interpreter2(newOrEdit) }}</v-btn
        >
      </v-row>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
import FrontCommon from "@/assets/js/frontCommon";

export default {
  components: {},
  data: () => ({
    defaultQuestions: [],
    newOrEdit: "new",
    id: "",
    title: "",
    questions: [],
    radios: "yes",
    check: {
      questions: true, // questionをバリデーションする
    },
    how2make: "form", // どのようにアンケートを作るか
    selectedQuestions: [],
  }),
  created() {
    if (this.$router.currentRoute.query.id) {
      this.id = this.$router.currentRoute.query.id;
      firebase
        .firestore()
        .collection("pre_exam_data")
        .doc(this.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.newOrEdit = "edit";
            this.title = doc.data().title;
            this.selectedQuestions = doc.data().selectedQuestions;
            this.questions = doc.data().questions;
          }
        });
    }
    if (this.$router.currentRoute.query.return) {
      this.title = this.$store.getters.predictionData.title;
      this.questions = this.$store.getters.predictionData.questions;
    }
    this.defaultQuestions = FrontCommon.defaultQuestions();
  },
  methods: {
    interpreter1(v) {
      if (v === "new") {
        return "作成";
      } else {
        return "編集";
      }
    },
    interpreter2(v) {
      if (v === "new") {
        return "作成";
      } else {
        return "更新";
      }
    },
    addPushed() {
      if (this.$refs.questionForm.validate()) {
        const add = {
          text: "",
          memo: "",
        };
        this.questions.push(add);
      }
    },
    deletePushed(i) {
      this.questions.splice(i, 1);
    },
    makePushed() {
      if (this.$refs.questionForm.validate()) {
        this.$store.commit("predictionData", {
          title: this.title,
          selectedQuestions: this.selectedQuestions,
          questions: this.questions,
          updated: new Date(),
        });
        if (this.newOrEdit === "new") {
          this.$router.push({
            name: "confirmPrediction",
          });
        } else {
          this.$router.push({
            name: "confirmPrediction",
            query: { id: this.id },
          });
        }
      }
    },
    translateQuestions(question) {
      return this.defaultQuestions.find((el) => el.value === question);
    },
  },
  computed: {},
  watch: {
    how2make() {
      if (this.id) {
        this.$router.push({
          name: "makePredictionJson",
          query: { id: this.id },
        });
      } else {
        this.$router.push({ name: "makePredictionJson" });
      }
    },
  },
  //   filters: {
  //     translateQuestions(question) {
  //       return this.defaultQuestions.find((el) => el.text == question);
  //     },
  //   },
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
